import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"
import './style.scss'

export default () => (
    <StaticQuery
    query={graphql`
        query OrganizationsQuery {
            allOrganizationsJson {
                edges {
                    node {
                        name
                        position
                        url
                        image {
                            childImageSharp {
                                fixed(width: 24, height: 24) {
                                    ...GatsbyImageSharpFixed_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    `}
    render={data => (
        <div className="organizations">
        {data.allOrganizationsJson.edges.map(org => (
            <div className="organization" key={org.node.name}>
                <span className="name">
                    <a href={org.node.url} title={org.node.name}>
                        <Img fixed={org.node.image.childImageSharp.fixed} alt={org.node.name} className="logo" />
                        {org.node.name}
                    </a>
                </span>
                <span className="position">{org.node.position}</span>
            </div>
        ))}        
        </div>
    )
  }
  />
)
